@import "header.scss";
@import "setting.scss";
@import "footer.scss";

:root {
	--main: hsl(160, 7%, 25%);
	--accent: hsl(345, 90%, 60%);
	--background: hsl(34, 14%, 91%);
	--visible-main: hsl(160, 70%, 40%);

	font-family: "Courier New", Courier, monospace;
	font-size: 10px;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
}

body {
	display: flex;
	flex-direction: column;
	height: 100vh;
	height: 100dvh;
	background-color: var(--main);
}

main {
	position: relative;
	background-color: var(--main);
}

#main-game {
	width: 100%;
	display: block;
	margin: auto;
	transition: max-width 0.2s, max-height 0.2s;

	&:focus-visible {
		box-shadow: 0 0 0 2px var(--visible-main);
		outline: 2px solid var(--background);
		outline-offset: 4px;
	}
}
