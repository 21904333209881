/*



*/
/*****************/
/***  Setting  ***/
/*****************/

#setting {
	display: none;
	position: relative;
	width: 100%;
	max-width: 750px;
	margin: auto;
	padding-top: 0.7em;

	background-color: var(--background);
	font-size: 2rem;

	border-radius: 1.3em;
	transition: border-radius 0.2s;
}

#setting h2 {
	text-align: center;
	font-size: clamp(1.8rem, 10vw - 0.72rem, 3.6rem);
	color: var(--main);
}

#exit-setting {
	position: absolute;
	top: 0;
	right: 0;

	font-size: min(1em, 2vw + 0.5em);
	height: 3.1em;
	width: 3.1em;

	border: none;
	background: none;
	cursor: pointer;
	border-radius: 50%;

	--rotate: 45deg;
	&:hover {
		--rotate: 35deg;
	}

	&::after {
		content: "";
		position: absolute;
		inset: 0;
		border-radius: 50%;
	}
	&:focus-visible {
		outline: 2px solid var(--main);
		&::after {
			outline: 2px solid var(--visible-main);
			outline-offset: 3px;
		}
	}
}
.cross-bar {
	position: absolute;
	height: 0.1em;
	width: 1.6em;
	left: 50%;
	translate: -50% 0;
	border-radius: 0.1em;
	background-color: var(--accent);

	transition: rotate 0.3s;
}
.bar-1 {
	rotate: calc(var(--rotate) * -1);
}
.bar-2 {
	rotate: var(--rotate);
}

form {
	font-size: clamp(0.6em, 5vw, 1em);
	padding: 1.1em 1em;
	color: var(--main);
}

fieldset {
	border: 1px solid var(--accent);
	border-radius: 0.7em;
	transition: border-radius 0.2s;

	padding: 0.5em 1.3em;

	&:not(:last-child) {
		margin-bottom: 1.3em;
	}
}

legend {
	position: relative;
	padding: 0 0.7em 0.2em;
	font-size: 1.3em;

	#snake-preview {
		width: calc(77vw - 100%);
		position: absolute;
		left: 6em;
		top: 50%;
		transform: translateY(-50%);
	}
}

dt,
dd {
	display: inline;
}

.setting-descriptions {
	font-size: 0.9em;
	opacity: 0.7;
}

/**/

/*** Gestion des boutons radio personnalisé ***/

label {
	display: block;
	position: relative;
	padding-left: calc(25px + 1em);
	padding-block: 0.55em;
	cursor: pointer;

	&:hover {
		color: var(--accent);
	}
}

/* Cache le bouton radio par défault */
label input[type="radio"] {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

/* Créer un bouton radio personnalisé */
.checkmark {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0px;
	height: 25px;
	width: 25px;
	background-color: #fff;
	border-radius: 50%;

	transition: opacity 0.2s, background-color 0.2s;

	&::after {
		content: "";
		position: absolute;
		inset: 0;
		border-radius: 50%;
	}
}

/* Pour la selection en cours : */
label input[type="radio"]:checked + .checkmark {
	background-color: var(--accent);
}

/* Créer un bouton color personnalisé */
input[type="color"] {
	--border-radius: 50px;

	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: -4px;
	height: 25px;
	width: 35px;

	border-radius: var(--border-radius);
	appearance: none;
	border: none;

	&::-webkit-color-swatch-wrapper,
	&::-webkit-color-swatch {
		padding: 0;
		border: none;
		border-radius: var(--border-radius);
	}

	&::-moz-color-swatch,
	&::-moz-focus-inner {
		padding: 0;
		border: none;
		border-radius: var(--border-radius);
	}
}

/* Focus */
input[type="radio"]:focus-visible + .checkmark {
	outline: 2px solid var(--main);
	outline-offset: 2px;
	&::after {
		outline: 2px solid var(--visible-main);
		outline-offset: 5px;
	}
}
input[type="color"]:focus-visible {
	outline: 2px solid var(--main);
	outline-offset: 2px;
	&::-webkit-color-swatch-wrapper {
		outline: 2px solid var(--visible-main);
		outline-offset: 5px;
	}
	&::-moz-color-swatch {
		outline: 2px solid var(--visible-main);
		outline-offset: 5px;
	}
}

/* Au survol : light */
label:hover {
	input:not(:checked) + .checkmark {
		background-color: var(--accent);
		opacity: 0.4;
	}
	input[type="color"] {
		opacity: 0.7;
	}
}
