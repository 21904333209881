/*



*/
/****************/
/***  Footer  ***/
/****************/

footer {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 0.7em;
	height: 100%;
	min-height: 100px;

	font-size: 1.4em;
	background-color: var(--main);

	ul {
		list-style: none;
	}

	.author-link {
		position: relative;
		display: inline-block;
		text-decoration: none;

		color: var(--accent);
		font-weight: bold;
		text-align: center;

		padding-inline: 1em;
		padding-block: 0.3em 0.8em;
		border-radius: 4em;

		&::after {
			content: "";
			position: absolute;
			inset: 0;
			border-radius: 4em;
		}
		&:focus-visible {
			outline: 2px solid var(--background);
			&::after {
				outline: 2px solid var(--visible-main);
				outline-offset: 4px;
			}
		}

		@media (width <= 300px) {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 0.7em;
		}

		svg {
			width: 1.7em;
			position: relative;
			top: 0.3em;
			@media (width <= 300px) {
				left: 0.3em;
			}

			.logo-color {
				fill: var(--accent);
			}
		}

		path {
			transition: d 0.4s linear;
		}

		transition: scale 0.4s;
		&:hover {
			scale: 1.07;
		}

		--x-curved: "M107.98, 185.12 C74.85, 115.49, 61.72, 45.86, 61.72, 45.86 c0, 0, 21.19, 69.63, 62.38, 139.26";
		--y-curved: "M0, 185.12 C7.3, 104.22, 34.59, 23.32, 34.59, 23.32 c0, 0, -20, 80.9, -20, 161.8";
		&:hover .l-path {
			d: path(var(--y-curved));
		}
		&:hover .x-path {
			d: path(var(--x-curved));
		}
	}
}
