/*


*/
/****************/
/***  Header  ***/
/****************/

header {
	background-color: var(--main);
	min-height: 130px;
	padding: 0 5vw;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

h1 {
	display: inline-block;
	font-family: "Coiny", "Courier New", Courier, monospace;
	font-size: min(6rem, 12vw);
	line-height: 0px;

	color: var(--background, #fff);
}

#title .three-dots {
	position: relative;
	bottom: 0.03em;
	font-family: serif;
	font-size: 2em;

	color: var(--accent);
}

#navBar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 300px;
}

#scores {
	font-size: clamp(1.6em, 3.82vw + 0.58em, 2.6em);
	color: var(--background, #fff);
}

#buttons {
	display: flex;
}

#setting-icon,
#fullscreen-button {
	height: 6rem;
	width: 6rem;
	padding: 1.4rem;

	position: relative;
	display: block;
	flex-shrink: 0;
	border-radius: 50%;

	background: none;
	border: none;
	cursor: pointer;

	&:focus-visible {
		outline: 2px solid var(--background);
		&::after {
			outline: 2px solid var(--visible-main);
			outline-offset: 4px;
		}
	}
}
#setting-icon {
	svg {
		transition: transform 1.1s;
		overflow: visible;
	}
	#setting-gear,
	#setting-exit {
		opacity: 1;
		transition: opacity 0.5s;
	}
	&[data-settings="off"] #setting-exit {
		opacity: 0;
	}
	&[data-settings="on"] #setting-gear {
		opacity: 0;
	}
	#setting-gear {
		scale: 0.9;
		transform-origin: center;
	}

	&[data-settings="off"]:hover svg {
		transform: rotate(130deg);
	}
	#setting-exit line {
		transition: transform 0.3s;
		transform-origin: center;
	}
	&[data-settings="on"]:hover #setting-exit {
		line {
			transform: rotate(-15deg);
		}
		line:last-child {
			transform: rotate(15deg);
		}
	}
}

#fullscreen-button {
	display: none;
	margin-inline: -1rem -1.4rem;

	svg {
		overflow: visible;
	}

	#arrow-up,
	#arrow-down {
		transition: translate 0.6s;
	}

	&:hover #arrow-up {
		translate: 7% -7%;
	}
	&:hover #arrow-down {
		translate: -7% 7%;
	}
}

.spike {
	transition: translate 0.5s;
}

[data-fullscreen="on"] {
	#arrow-down .spike {
		translate: 40% -40%;
	}
	#arrow-up .spike {
		translate: -40% 40%;
	}
}

@media (width <= 875px) and (height > 500px), (width <= 490px) {
	header {
		flex-direction: column;
		justify-content: space-evenly;
	}
	#navBar {
		width: 100%;
		align-items: center;
	}
	#scores {
		display: flex;
		width: 100%;
		max-width: 500px;
		justify-content: space-between;
	}
	#scores p {
		margin-right: 5vw;
	}
	#setting-icon,
	#fullscreen-button {
		height: 5rem;
		width: 5rem;
		padding: 1.1rem;
	}
	#fullscreen-button {
		margin-inline: -0.7rem -1.1rem;
	}
}

@media (width <= 375px) {
	#scores {
		flex-direction: column;
	}
}
@media (height <= 500px) {
	header {
		min-height: 80px;
	}
	#title {
		font-size: min(2.5em, 9vw);
	}
	#navBar {
		font-size: 0.8em;
	}
}

@media (height <= 500px) and (width > 490px) {
	header {
		flex-direction: row;
	}
	#title {
		font-size: min(5.5em, 7vw);
	}
	@media (width <= 875px) {
		#navBar {
			width: auto;
		}
	}
	#scores {
		flex-direction: column;
		align-items: flex-end;
		margin-right: 5vw;
		font-size: clamp(1.6em, 3vw + 0.6em, 2.6em);
	}
	#setting-icon,
	#fullscreen-button {
		height: 5rem;
		width: 5rem;
		padding: 1.1rem;
	}
	#fullscreen-button {
		margin-inline: -0.7rem -1.1rem;
	}
}
